<template>
  <div class="login-container">
    <el-row>
      <el-col :span="6" :offset="9">
        <h1 class="login-title">社群管理系统</h1>
        <el-form ref="loginForm" :model="loginForm" :rules="rules" class="login-form">
          <el-form-item class="login-form-item" prop="username">
            <el-input v-model="loginForm.username" placeholder="请输入用户名"></el-input>
          </el-form-item>
          <el-form-item class="login-form-item" prop="password">
            <el-input v-model="loginForm.password" type="password" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="login-btn" type="primary" @click="onSubmit">登录</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import SQLoading from '../utils/loading'
export default {
  name: 'LoginPage',
  data() {
    // 验证手机号
    let validateTel = (rule, value, callback) => {
      if(!value.match(/1[3456789]\d{9}/)){
        callback(new Error("请输入正确的手机号"))
      }else{
        callback()
      }
    }
    return {
      // 登录表单
      loginForm: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          {required: true, message: "请输入手机号码！", trigger: "blur"},
          {validator: validateTel, trigger: "blur"}
        ],
        password: [
          {required: true, message: "请输入密码！", trigger: "blur"},
          {min: 6, max: 20, message: "密码字符在6-20之间", trigger: "blur"},
        ]
      }
    }
  },
  methods: {
    onSubmit() {
      this.$refs['loginForm'].validate(valid => {
        if(!valid){
          console.log("验证失败")
          return
        }
        const params = {
          username: this.loginForm.username,
          password: this.loginForm.password
        }
        SQLoading.show()
        this.$http.login(params).then(res => {
          const data = res.data
          const token = data.token
          const user = data.user
          this.$auth.setUserToken(user, token)
          this.$router.push("/")
          SQLoading.hide()
        }).catch(err => {
          console.log("登录失败：", err)
        }).finally(() => {
          SQLoading.hide()
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.login-container {
  padding-top: 100px;
  .login-title {
    text-align: center;
  }
  .login-form {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .login-form-item {
    width: 300px;
  }
  .login-btn {
    width: 300px;
  }
  .error-msg {
    color: red;
    margin-top: 5px;
  }
}
</style>
