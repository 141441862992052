<template>
  <div class="frame-container">
    <el-container>
      <el-header class="header" style="height: 50px;">
        <a href="/" class="brand">社群管理系统</a>
        <div class="header-content" v-if="$auth.user">
          <div class="greet">欢迎&nbsp;&nbsp;{{ $auth.user.username }}</div>
          <div class="logout" @click="onLogout">退出登录</div>
        </div>
      </el-header>
      <el-container>
        <el-aside width="200px" class="aside">
          <el-row class="menu-row">
          <el-col :span="24">
            <el-menu default-active="/" background-color="#545c64"
              class="el-menu" active-text-color="#fff" text-color="#ddd" :router="true">
              <el-menu-item index="/">
                <i class="el-icon-s-data"></i>
                <span slot="title">仪表盘</span>
              </el-menu-item>
              <el-menu-item index="/groups">
                <i class="el-icon-s-custom"></i>
                <span slot="title">社群</span>
              </el-menu-item>
              <el-menu-item index="/category">
                <i class="el-icon-menu"></i>
                <span slot="title">群分类</span>
              </el-menu-item>
              <el-menu-item index="/user">
                <i class="el-icon-user-solid"></i>
                <span slot="title">用户</span>
              </el-menu-item>
              <el-menu-item index="/order">
                <i class="el-icon-s-order"></i>
                <span slot="title">订单</span>
              </el-menu-item>
              <el-menu-item index="/tools">
                <i class="el-icon-s-tools"></i>
                <span slot="title">设置</span>
              </el-menu-item>
            </el-menu>
          </el-col>
        </el-row>          
        </el-aside>
        <el-main class="main">
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: "FramePage",
  data() {
    return {};
  },
  components: {},
  methods: {
    onLogout() {
      this.$auth.clearUserToken()
      this.$router.replace('/login')
    }
  }
}
</script>

<style scoped lang='scss'>
.frame-container {
  .header {
    background: #0088ff;
    display: flex;
    .brand {
      width: 200px;
      margin-left: -20px;
      background-color: #434a50;
      font-size: 20px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .header-content {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 20px;
      color: #fff;
      .logout {
        cursor: pointer;
      }
    }
  }
  .aside {
    height: 92vh;
    background-color: #545c64;
    .el-menu {
      .is-active {
        background-color: #434a50 !important;
      }
    }
  }
  .main {
    height: 92vh;
    background-color: #e9eef3;
  }
}
</style>