<template>
  <div class="category_container">
    <!-- 添加分类按钮 -->
    <el-button class="add_btn" type="primary" @click="onAddCategory">添加分类</el-button>
    
    <!-- 分类列表 -->
    <el-table :data="groupscategory" style="width: 100%">
      <el-table-column label="ID" prop="id" width="460"></el-table-column>
      <el-table-column label="分类名称" prop="name" width="460"></el-table-column>
      <el-table-column label="操作">
        <template v-slot="scope">
          <el-button size="mini" @click="onEditCategory(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="onDeleteCategory(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination background layout="prev, pager, next" :total="total" :page-size="10" :current-page="page" 
    class="pagination" @current-change="onCurrentChange"></el-pagination>

    <!-- 添加/编辑分类 -->
    <el-dialog title="添加 / 编辑" :visible.sync="categoryDialogVisible" width="40%">
      <el-form ref="categoryForm" :model="categoryForm" :rules="rules">
        <el-form-item label="分类名" :label-width="formLabelWidth" prop="name">
          <el-input v-model="categoryForm.name" autocomplete="off" ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="categoryDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="onSubmitCategory">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import SQLoading from '../utils/loading'
import SQMessage from '../utils/message'
export default {
  name: "CategoryPage",
  
  data() {
    return {
      // 控制添加分类对话框的显示隐藏
      categoryDialogVisible: false,

      // 分类表单数据
      categoryForm: {
        id: '',
        name: ''
      },

      rules: {
        name: [{ required: true, message: "请输入分类名称", trigger: "blur" }]
      },
      
      formLabelWidth: '80px',
      groupscategory: [],

      // 分页
      total: 0,
      page: 1
    };
  },

  components: {},

  mounted() {
    this.getGroupsCategory(1)
  },

  methods: {
    // 初始化分类表单数据
    initCategoryForm() {
      this.categoryForm = {
        id: '',
        name: ''
      }
    },

    // 获取分类数据
    getGroupsCategory(page) {
      SQLoading.show()
      this.$http.getCategoryList(page).then(res => {
        const data = res.data
        this.groupscategory = data.results
        this.total = data.count
        this.page = page
        SQLoading.hide()
      }).catch((err) => {
        console.log("获取分类数据失败:", err)  
        SQLoading.hide()
      })
    },

    // 点击添加分类按钮
    onAddCategory() {
      this.initCategoryForm()
      this.categoryDialogVisible = true
    },

    // 点击编辑分类按钮
    onEditCategory(category) {
      this.categoryDialogVisible = true
      this.categoryForm = {
        id: category.id,
        name: category.name
      }
    },    

    // 点击删除分类按钮
    onDeleteCategory(id) {
      // 查找要删除的分类
      const categoryTargetDelete = this.groupscategory.find(category => category.id === id)
      if(categoryTargetDelete.groups_list.length > 0) {
        SQMessage.info("该分类下还有群，请先删除群")
        return
      }

      // 判断删除后当前页是否为空
      const remainingCategory = this.groupscategory.filter(category => category.id !== id)
      const currentPageEmpty = remainingCategory.length === 0
      // 从列表中删除分类
      this.$http.deleteCategory(id).then(() => {
        this.groupscategory = remainingCategory
        SQMessage.success("删除成功")

        // 若当前页为空，且当前页不是第一页，则导航到前一页
        if (currentPageEmpty && this.page > 1) {
          this.getGroupsCategory(this.page - 1)
        }
      }).catch(err => {
        SQMessage.error('删除失败', err)
      })
    },

    // 提交分类表单
    onSubmitCategory() {
      if (this.categoryForm.id) {
        // 更新分类数据
        this.$http.updateCategory(this.categoryForm.id, this.categoryForm).then(() => {
          SQMessage.success()
          this.categoryDialogVisible = false
          this.getGroupsCategory(this.page)  // 重新获取分类列表
        }).catch((err) => {
          this.SQMessage.error('编辑分类失败', err)
        })
      } else {
        // 新增分类数据
        this.$http.addCategory(this.categoryForm).then(res => {
          SQMessage.success()
          this.categoryDialogVisible = false
          const category = res.data
          this.groupscategory.push(category)
          this.getGroupsCategory(this.page)
        }).catch((err) => {
          this.SQMessage.error('添加分类失败', err)
        })
      }
    },

    // 点击分页按钮
    onCurrentChange(page) {
      this.getGroupsCategory(page)
    }
  }
}
</script>

<style scoped lang="scss">
.category_container {
  .add_btn {
    margin: 20px 0;
  }
  .el-table :deep .cell {
    padding-left: 20px;
    padding-right: 20px;
  }
  .pagination {
    padding-top: 20px;
    text-align: right;
  }
  .el-pagination {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
  .el-input :deep .el-input__inner {
    width: 410px;
  }
}
</style>