import VueRouter from 'vue-router'
import Vue from 'vue'
import auth from './utils/auth'
import Frame from './components/Frame'
import Login from './components/Login'
import Index from './components/Index'
import Groups from './components/Groups'
import Category from './components/Category'
import User from './components/User'
import Order from './components/Order'
import Tools from './components/Tools'


Vue.use(VueRouter)

const routes = [
  { 
    path: '/', 
    component: Frame, 
    children: [
      { 
        path: '', 
        component: Index, 
        name: 'index',
        meta: { requireAuth: true }
      },
      { 
        path: 'groups', 
        component: Groups, 
        name: 'groups',
        meta: { requireAuth: true }
      },
      {
         path: 'category', 
         component: Category, 
         name: 'category',
         meta: { requireAuth: true }
        },
      { 
        path: 'user', 
        component: User, 
        name: 'user',
        meta: { requireAuth: true }
      },
      { 
        path: 'order', 
        component: Order, 
        name: 'order',
        meta: { requireAuth: true }
      },
      { 
        path: 'tools', 
        component: Tools, 
        name: 'tools',
        meta: { requireAuth: true }
      }
    ] 
  },
  { path: '/login', 
    component: Login, 
    name: 'login' 
  }
]


const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth && !auth.is_authed) {
    next({
      "name": "login",
      "query": { "from": to.path }
    })
  } else if (to.name === 'Login' && auth.is_authed) {
    next({ name: 'index' })
  } else {
    next()
  }
})

router.onError((error) => {
  console.error('Routing Error:', error)
})

export default router