<template>
  <div class="groups_container">
    <!-- 群列表 -->
    <el-table :data="groups" style="width: 100%">
      <el-table-column label="ID" prop="id" width="60"></el-table-column>
      <el-table-column label="群名称" prop="name" width="292"></el-table-column>
      <el-table-column label="价格" prop="price" width="240"></el-table-column>
      <el-table-column label="城市" prop="city" width="240"></el-table-column>
      <el-table-column label="发布时间" prop="release_time" width="260"></el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination background layout="prev, pager, next" :total="total" :page-size="10" :current-page="page" 
    class="pagination" @current-change="onCurrentChange"></el-pagination>
  </div>
</template>

<script type="text/ecmascript-6">
import SQLoading from '../utils/loading'
export default {
  name: "GroupsyPage",
  
  data() {
    return {
      groups: [],

      // 分页
      total: 0,
      page: 1
    };
  },

  components: {},

  mounted() {
    this.getGroups(1)
  },

  methods: {
    // 获取群数据
    getGroups(page) {
      SQLoading.show()
      this.$http.getGroupsList(page).then((res) => {
        const data = res.data
        this.groups = data.results
        this.total = data.count
        this.page = page
        SQLoading.hide()
      }).catch((err) => {
        console.log("获取群数据失败:", err)  
        SQLoading.hide()
      })
    },

    // 点击分页按钮
    onCurrentChange(page) {
      this.getGroups(page)
    }
  }
}
</script>

<style scoped lang="scss">
.groups_container {
  .add_btn {
    margin: 20px 0;
  }
  .el-table :deep .cell {
    padding-left: 20px;
    padding-right: 20px;
  }
  .pagination {
    padding-top: 20px;
    text-align: right;
  }
  .el-pagination {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}
</style>