const USER_KEY = "USER_KEY"
const TOKEN_KEY = "TOKEN_KEY"


class Auth {
  // 构造函数，初始化加载用户信息和token
  constructor(){
    this.token = null
    this.user = null

    this.token = localStorage.getItem(TOKEN_KEY)
    const userJson = localStorage.getItem(USER_KEY)
    if(userJson){
      this.user = JSON.parse(userJson)
    }
  }

  // 获取单例
  static getInstance() {
    if(!this._instance){
      this._instance = new Auth()  // 确保静态成员正确设置
    }
    return this._instance
  }

  // 保存用户信息和token
  setUserToken(user, token){
    this.user = user
    this.token = token
    localStorage.setItem(USER_KEY, JSON.stringify(user))
    localStorage.setItem(TOKEN_KEY, token)
  }

  // 清除用户信息和token
  clearUserToken(){
    this.user = null
    this.token = null
    localStorage.removeItem(USER_KEY)
    localStorage.removeItem(TOKEN_KEY)
  }

  // 验证用户信息和token
  // get is_authed(){
  //   if(this.user && this.token){
  //     return true
  //   }else{
  //     return false
  //   }
  // }
  get is_authed() {
    return !!(this.user && this.token)  // 使用 !! 转换为布尔值，两个都可以
  }
}

export default Auth.getInstance()