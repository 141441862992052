<template>
<div class="">
  <h3>仪表盘</h3>
</div>
</template>

<script type="text/ecmascript-6">
export default {
  name: "IndexPage",
  data() {
    return {}
  },
  components: {},
}
</script>

<style scoped lang="scss">
</style>