import axios from "axios";
import auth from "./auth";
import router from "../routes";

// const BASE_URL = 'http://127.0.0.1:8000'  // 开发环境
const BASE_URL = 'http://120.27.109.202:8000'  // 生产环境

class Http {
  constructor() {
    this.http = axios.create({
      baseURL: BASE_URL,
      timeout: 1000
    });

    // 请求之前的拦截器，用来设置JWT
    this.http.interceptors.request.use((config) => {
      if (config && config.headers) {
        const token = auth.token;
        if (token) {
          config.headers.Authorization = "JWT " + token;
        }
      }
      return config
    })

    // 响应之后的拦截器
    this.http.interceptors.response.use(response => {
      return response
    }, err => {
      auth.clearUserToken()
      router.replace("/login")
      return Promise.reject(err)
    })
  }

  login(params){
    const url = "/cms/login"
    return this.http.post(url, params)
  }

  getCategoryList(page=1) {
    const url = "/cms/category?page=" + page
    return this.http.get(url)
  }

  addCategory(data){
    const url = "/cms/category"
    return this.http.post(url, data)
  }

  updateCategory(category_id, data){
    const url = "/cms/category/" + category_id
    return this.http.put(url, data)
  }

  deleteCategory(category_id){
    const url = "/cms/category/" + category_id
    return this.http.delete(url)
  }

  getGroupsList(page=1) {
    const url = "/cms/groups?page=" + page
    return this.http.get(url)
  }

  addGroups(data){
    const url = "/cms/groups"
    return this.http.post(url, data)
  }

  updateGroups(id, data){
    const url = "/cms/groups/" + id
    return this.http.put(url, data)
  }

  deleteGroups(id){
    const url = "/cms/groups/" + id
    return this.http.delete(url)
  }
}

export default new Http()