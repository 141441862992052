import { Loading } from "element-ui";

class SQLoading{
  // 初始化加载实例
  constructor() {
    this.loading = null;
  }

  // 显示loading
  show(text=null){
    this.loading = Loading.service({
      fullscreen: true,
      background: "rgba(0, 0, 0, 0.7)",
      spinner: "el-icon-loading",
      text: text ? text:"正在加载中..."
    })
  }

  // 隐藏loading
  hide(){
    if(this.loading){
      this.loading.close()
    }
  }
}

export default new SQLoading()